.best-author {
	
	&--inner {
		margin-left: -10px;
		margin-right: -10px;
		display: flex;
	}
	
	&--item {
		display: flex;
		flex-direction: column;
		cursor: pointer;
		width: 135px;
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 16px;
	}
	
	&--image {
		width: 135px;
		height: 135px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border-radius: 50%;
		background-color: #ddd;
	}
	
	&--title {
		margin-top: 9px;
		font-size: 13px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.08px;
		text-align: center;
		color: #222222;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	
	&--ppl {
		margin-top: 2px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.08px;
		text-align: center;
		color: #939393;
	}
}