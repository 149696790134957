.modal-del {

	&--body {
		padding: 20px 25px;
		background: #fff;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.46;
		letter-spacing: -0.08px;
		color: #000000;
	}

	&--footer {
		border-top: 1px solid #e7e8ec;
		background: #f7f8fa;
		padding: 14px 25px;
		display: flex;
		justify-content: flex-end;

		.Button + .Button {
			margin-left: 10px;
		}
	}
}
