.category-list {
	display: flex;

	&--left {
		width: calc(50% - 8px);
		margin-right: 8px;
	}

	&--right {
		width: calc(50% - 8px);
		margin-left: 8px;
	}

	.Checkbox {
		padding: 4px 15px 4px 12px;
		display: inline-block;
		width: 100%;
		box-sizing: border-box;

		&__container {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
		}

		&__icon {
			width: 15px;
			height: 15px;
			border: 1px solid #c1c9d1;
			border-radius: 3px;
			margin: 0;
		}

		.Icon--done_16 {
			width: 12px;
			height: 12px;

			svg {
				width: 12px;
				height: 14px;
			}
		}

		&__content {
			display: flex;
			align-items: center;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: -0.15px;
			color: #000000;
		}

		.icon-category {
			background-repeat: no-repeat;
			background-size: contain;
			width: 40px;
			min-width: 40px;
			height: 40px;
			margin-right: 8px;
		}
	}
}
