.onboard {
	padding: 48px 85px;

	.Checkbox {

		&__input:checked ~ .Checkbox__container .Checkbox__icon {
			background: #5b88bd url('../img/icon/check.svg') center no-repeat;
			background-size: 9px 10px;
			border-color: #5b88bd;
		}

		.Icon {
			display: none;
		}
	}

	img {
		width: 128px;
		height: 128px;
		margin-right: 32px;
	}

	&--title {
		margin-bottom: 8px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.32px;
		text-align: center;
		color: #000000;
	}

	&--subtitle {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #656565;
	}

	&--content {
		margin-top: 28px;
		margin-bottom: 40px;
	}

	.tooltip-btn {
		position: relative;
		display: flex;
		justify-content: center;

		.button-with-loader.disabled:hover + .tooltip-btn--box {
			display: block;
		}

		&--box {
			display: none;
			position: absolute;
			bottom: calc(100% + 16px);
			left: 50%;
			transform: translateX(-50%);
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
			border: solid 1px #c5d0db;
			border-radius: 3px;
			padding: 12px 15px;
			background-color: #ffffff;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: -0.08px;
			color: #000000;

			&:before, &:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				right: 25px;
				border: 6px solid transparent;
				height: 0;
				width: 0;
				pointer-events: none;
			}

			&:before {
				margin: 0 -3px;
				border-top-color: #c5d0db;
			}
			&:after {
				margin: -2px -3px;
				border-top-color: #fff;
			}
		}
	}
}
