.tooltip-search, .tooltip {
	position: absolute;
	z-index: 1;
	padding: 4px 9px 5px;
	display: none;
	font-size: 12.5px;
	line-height: normal;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	white-space: nowrap;
	letter-spacing: normal;
	pointer-events: none;
	color: #ffffff;
	background: rgba(#0e0e0e, .72);
	border-radius: 4px;

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		margin: 0 -5px;
		width: 0;
		height: 0;
		pointer-events: none;
		border: 5px solid transparent;
		border-top-color: rgba(#0e0e0e, .72);
	}

	&--center:before {
		left: 50%;
	}

	&--left:before {
		left: 21px;
	}

	&--right:before {
		right: 21px;
	}

	&.active {
		display: block;
	}
}
