.welcome {
	padding: 41px 32px 48px;
	font-family: SFPro;

	&--center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&--image {
		width: 350px;
		height: 280px;
		border-radius: 8px;
		background-image: url("../img/welcome-pic.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	&--title {
		margin-top: 47px;
		margin-bottom: 24px;
		font-family: SFProD;
		font-size: 22px;
		line-height: normal;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: .35px;
		color: #222222;
	}

	&--subtitle {
		margin-bottom: 48px;
		font-size: 16px;
		line-height: 1.44;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: -.32px;
		color: #000000;
	}

	.Checkbox {
		margin-bottom: 20px;
		padding: 0;

		&__input:checked ~ .Checkbox__container .Checkbox__icon {
			background: #5b88bd url('../img/icon/check.svg') center no-repeat;
			background-size: 9px 10px;
			border-color: #5b88bd;
		}

		&__container {
			align-items: center;
		}

		&__icon {
			margin-right: 8px;
			width: 15px;
			height: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #c1c9d1;
			border-radius: 3px;
		}

		&__content {
			font-size: 13px;
			line-height: normal;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #000000;

			a {
				color: #2a5885;
			}
		}

		.Icon {
			display: none;
		}
	}
}

.home-zero {
	padding: 20px;
	border: 1px solid #e3e4e8;
	border-radius: 4px;

	.welcome-one--subtitle {
		margin-bottom: 24px;
	}

	.UsersStack {
		margin-top: 36px;
	}
}
