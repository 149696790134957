.white-block {
	position: relative;
	width: 100%;
	font-family: SFPro;
	background: #ffffff;
	border: 1px solid #e7e8ec;
	border-radius: 4px;
	box-sizing: border-box;

	& + & {
		margin-top: 13px;
	}

	&--name {
		padding-right: 20px;
		padding-left: 20px;
		height: 62px;
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.32px;
		color: #000000;

		& + .white-block--content {
			padding-top: 0;
		}
	}

	&--content {
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;
	}

	&-main {
		position: relative;
		width: 100%;
		font-family: SFPro;
		background: #ffffff;
		border: 1px solid #e7e8ec;
		border-radius: 4px;
		box-sizing: border-box;

		&--head {
			padding-right: 20px;
			padding-left: 20px;
			height: 54px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #000000;
			background-color: #fafbfc;
			border-bottom: 1px solid #e7e8ec;
			border-radius: 4px 4px 0 0;
		}

		&--content {
			padding-top: 20px;
			padding-right: 20px;
			padding-left: 20px;
		}

		& + .white-block {
			margin-top: 13px;
		}
	}
}

