.top-marathon {
	margin-bottom: 20px;
	height: 172px;
	cursor: pointer;

	&--image {
		position: relative;
		padding: 16px 16px 16px 20px;
		box-sizing: border-box;
		height: 100%;
		display: flex;
		align-items: flex-end;
		background-color: rgba(#000000, .6);
		border-radius: 4px;
	}

	&--info {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&--title {
		font-family: SFProD;
		font-size: 32px;
		line-height: 1.13;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: .38px;
		color: #ffffff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--bottom {
		margin-top: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--numbers {
		display: flex;
		align-items: center;
	}

	&--ppl {
		font-size: 16px;
		line-height: 1.31;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.18px;
		color: rgba(255, 255, 255, .75);
	}

	&--exercises {
		padding: 4px 6px;
		font-size: 14px;
		line-height: 1.29;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.15px;
		color: #ffffff;
		background: rgba(#000000, .4);
		border-radius: 4.8px;
	}
}
