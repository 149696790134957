.search-custom {
	border: 1px solid #e7e8ec;
	border-top: none;
	background-color: #fff;

	input {
		font-family: SFPro;
		font-size: 13px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #000000;
	}

	.Search {
		padding: 5px 8px;
		background: transparent;

		&__input {
			padding-left: 40px;
		}

		&__control {
			background: transparent;
		}

		&__after-width {
			display: none;
		}

		&__after {

			&-in {
				display: none;
			}

			.Search__icon {
				display: block;
				background-image: url('../img/icon/close-blue.svg');
				background-repeat: no-repeat;
				cursor: pointer;
				background-position: center;
				background-size: 11px;
			}
		}

		&__icons {

			.Icon {
				display: none;
			}
		}

		&__placeholder-text {
			margin-left: 11px;
			font-family: SFPro;
			font-size: 13px;
			line-height: normal;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #929eb0;
		}

		.Icon {
			color: #86a1bf;
		}
	}

	+ .white-block {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.tooltip-search {
		position: absolute;
		top: -20px;
		right: 8px;
		padding: 4px 9px 5px;
		display: none;
		font-size: 12.5px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		color: #ffffff;
		background: rgba(#0e0e0e, .72);
		border-radius: 4px;

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			right: 21px;
			margin: 0 -6px;
			width: 0;
			height: 0;
			pointer-events: none;
			border: 6px solid transparent;
			border-top-color: rgba(#0e0e0e, .72);
		}

		&.active {
			display: block;
		}
	}

	:not(.Search--focused) + .tooltip {
		display: none;
	}
}
