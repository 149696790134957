.modal-error {
	width: 450px;
	overflow: hidden;
	color: #000;
	font-family: SFPro;
	border-radius: 4px;

	&--header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		width: 100%;
		height: 54px;
		border-radius: 4px 4px 0 0;
		padding: 0 25px;
		background: #5b88bd;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.15px;
		color: #ffffff;
	}

	.modal--close {
		position: relative;
		opacity: .5;
		top: 0;
		right: 0;
	}

	&--body {
		padding: 20px 25px;
		background: #fff;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.46;
		letter-spacing: -0.08px;
		color: #000000;
	}

	&--footer {
		border-top: 1px solid #e7e8ec;
		background: #f7f8fa;
		padding: 14px 25px;
		display: flex;
		justify-content: flex-end;

		.Button + .Button {
			margin-left: 10px;
		}
	}
}
