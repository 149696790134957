.custom-select {
	position: relative;

	&:hover .custom-select--items {
		opacity: 1;
		pointer-events: all;
	}

	&.hide:hover .custom-select--items {
		opacity: 0;
		pointer-events: none;
	}

	&--between {
		z-index: 1;
		position: absolute;
		bottom: -5px;
		height: 5px;
		width: calc(100% - 16px);
		left: 8px;
		right: 8px;
	}

	&--selected {

		.main {
			display: flex;
			align-items: center;
		}
	}

	.arrow-down {
		margin-top: 1px;
		margin-left: 6px;
		width: 0;
		height: 0;
		border-width: 4px 4px 0;
		border-style: solid;
		border-color: #a9b6ca transparent transparent;
	}

	&--items {
		position: absolute;
		top: calc(100% + 4px);
		left: 8px;
		z-index: 9;
		display: flex;
		flex-direction: column;
		text-align: left;
		background-color: #ffffff;
		border: solid 1px #c5d0db;
		pointer-events: none;
		opacity: 0;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
		height: 200px;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			top: -5px;
			height: 4px;
			left: 0;
			right: 0;
		}
	}

	&--item {
		padding: 7px 16px 8px;
		max-height: 30px;
		font-size: 13px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		white-space: nowrap;
		letter-spacing: -.08px;
		color: #2a5485;
		cursor: pointer;

		&:first-child {
			margin-top: 5px;
		}

		&:last-child {
			margin-bottom: 5px;
		}

		&:hover {
			background: #e4eaf0;
		}
	}
}
