.marathon-item {
	margin-right: 5px;
	margin-bottom: 20px;
	margin-left: 5px;
	display: flex;
	flex-direction: column;

	&--header {
		height: 55px;
		display: flex;
		align-items: center;
	}

	&--image {
		position: relative;
		margin-bottom: 8px;
		height: 62px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 4px;
		cursor: pointer;
	}

	&--amount {
		position: absolute;
		right: 13px;
		bottom: 12px;
		padding: 2px 4px 1px;
		font-size: 11px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: .07px;
		color: #ffffff;
		background-color: rgba(#000000, .4);
		border-radius: 4px;
	}

	&--content {
		display: flex;
		flex-direction: column;
	}

	&--info {
		display: flex;
		flex-direction: column;

		&.two-row .marathon-item--title {
			-webkit-line-clamp: 1;
		}
	}

	&--title {
		position: relative;
		display: -webkit-box;
		overflow: hidden;
		font-size: 13px;
		line-height: normal;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		text-overflow: ellipsis;
		color: #222222;
		cursor: pointer;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&--description {
		display: -webkit-box;
		overflow: hidden;
		font-size: 13px;
		line-height: 1.23;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		text-overflow: ellipsis;
		color: #76787a;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	&--footer {
		margin-top: 4px;
		min-height: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 13px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #939393;
	}

	&--icon {
		color: #aaaeb3;

		> .icon {
			cursor: pointer;
		}
	}

	&--list {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;

		&-inner {
			margin-right: -5px;
			margin-bottom: -20px;
			margin-left: -5px;
			display: flex;
			flex-wrap: wrap;
			width: calc(100% + 10px);

			.marathon-item {
				width: calc(33.33% - 10px);
			}
		}
	}
}
