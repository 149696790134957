.rating-stars-vote {
	display: flex;
	margin-left: 7px;
	cursor: pointer;

	> div:hover {
		opacity: 0.7;
	}

	> div + div {
		margin-left: 5px;
	}
}
