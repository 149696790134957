@font-face { font-family: 'SFPro'; font-weight: 400; font-style: normal; src: url('../font/SFProText-Regular.ttf'); }
@font-face { font-family: 'SFPro'; font-weight: 500; font-style: normal; src: url('../font/SFProText-Medium.ttf'); }
@font-face { font-family: 'SFProD'; font-weight: 400; font-style: normal; src: url('../font/SFProDisplay-Regular.ttf'); }
@font-face { font-family: 'SFProD'; font-weight: 500; font-style: normal; src: url('../font/SFProDisplay-Medium.ttf'); }

body {
	background-color: #edeef0;
}

.Tappable--ios.Tappable--active:not([disabled]):not(.TabsItem):not(.PanelHeaderButton):not(.Button):not(.PanelHeaderContent__in):not(.ActionSheetItem):not(.Banner__in) {
	background: transparent !important;
}

.Button, .Radio, .Checkbox {
	cursor: pointer;
}

.Button {
	border-radius: 4px;

	.Button__content {
		font-size: 12.5px;
		font-weight: normal;
	}

	&--lvl-primary {
		background-color: #5181b8;
		border-radius: 4px;
	}
}

.block-center {
	margin-right: auto;
	margin-left: auto;
	display: block;
}

.ml-a {
	margin-left: auto;
}

.bg-white {
	background: #ffffff !important;
}

.pagination {
	margin-left: auto;
	display: flex;

	span {
		position: relative;
		min-width: 19px;
		font-size: 14px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		text-align: center;
		letter-spacing: -.15px;
		color: #939393;
		cursor: pointer;

		&.active {
			font-weight: 500;
			color: #000000;
		}
	}

	&--profile {
		position: absolute;
		top: -30px;
		right: 25px;

		span.active:before{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -14px;
			height: 2px;
			pointer-events: none;
			background: #5181b8;
		}
	}

	&--home {
		position: absolute;
		top: 20px;
		right: 25px;

		span.active:before {
			content:none;
		}
	}

	&--bottom {
		height: 49px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-top: 1px solid #e7e8ec;

		span.active:before {
			content: '';
			position: absolute;
			top: -18px;
			left: 0;
			right: 0;
			height: 2px;
			pointer-events: none;
			background: #5181b8;
		}
	}
}

.profile-interests {
	padding: 32px 85px 39px;
	border: 1px solid #e3e4e8;
	border-top: none;
	border-radius: 0 0 4px 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;

	.category-list {
		margin-bottom: 32px;
	}

	.tooltip-btn {
		position: relative;
		display: flex;
		justify-content: center;

		.button-with-loader.disabled:hover + .tooltip-btn--box {
			display: block;
		}

		&--box {
			display: none;
			position: absolute;
			bottom: calc(100% + 16px);
			left: 50%;
			transform: translateX(-50%);
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
			border: solid 1px #c5d0db;
			border-radius: 3px;
			padding: 12px 15px;
			background-color: #ffffff;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: -0.08px;
			color: #000000;
			white-space: nowrap;

			&:before, &:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				right: 25px;
				border: 6px solid transparent;
				height: 0;
				width: 0;
				pointer-events: none;
			}

			&:before {
				margin: 0 -3px;
				border-top-color: #c5d0db;
			}
			&:after {
				margin: -2px -3px;
				border-top-color: #fff;
			}
		}
	}
}


.radio-custom {
	padding: 0;

	&:hover .Radio__icon{
		background: #f2f4f8;
	}

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	.Radio {

		&__icon {
			margin-right: 8px;
			width: 15px;
			height: 15px;
			border: 1px solid #c1c9d1;
		}

		&__content {
			font-size: 13px;
			line-height: 1.5;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #000000;
		}
	}

	.Radio__input:checked~ .Radio__container .Radio__icon {
		border-color: #5181b8;

		&:after {
			top: 3px;
			left: 3px;
			width: 7px;
			height: 7px;
			background: #5181b8;
		}
	}
}

.checkbox-custom {
	padding: 0;

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	&:hover .Checkbox__icon{
		background: #f2f4f8;
	}

	.Checkbox {

		&__container {
			align-items: center;
		}

		&__icon {
			margin-right: 8px;
			margin-left: 0;
			width: 15px;
			height: 15px;
			border: 1px solid #c1c9d1;
			border-radius: 3px;

			.Icon {
				display: none;
			}
		}

		&__content {
			font-size: 13px;
			line-height: normal;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #000000;
		}

		&__input:checked~ .Checkbox__container .Checkbox__icon {
			border-color: #5b88bd;
			background: #5b88bd url('../img/icon/check.svg') center no-repeat;
			background-size: 9px 10px;
		}
	}
}

.form-label {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	> label:first-child {
		margin-bottom: 12px;
		font-size: 13px;
		line-height: normal;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #222222;
	}

	input {
		padding: 5px 10px 6px;
		box-sizing: border-box;
		width: 100%;
		font-size: 13px;
		line-height: 1.46;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #000000;
		background-color: #ffffff;
		border: solid 1px #d3d9de;
		border-radius: 3px;
		outline: none;

		&:focus {
			border: solid 1px #d3d9de;
		}

		&::placeholder {
			color: #828282;
		}
	}
}

.empty-search {
	background: #fff;
	height: 473px;
	border: 1px solid #e7e8ec;
	border-top: none;
	border-radius: 0 0 4px 4px;
	box-sizing: border-box;
	font-family: SFPro;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: -0.08px;
	color: #939393;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay_primary {

	.opacity {
		background-color: rgba(255, 255, 255, 0.4);
	}
}

.icon-reorder {
	background: url('../img/icon/reorder.svg') center no-repeat;
	background-size: contain;
	min-width: 20px;
	width: 20px;
	height: 12px;
}

.Checkbox__input[disabled]~.Checkbox__container {
	opacity: 1;
}

.Radio__input:disabled~.Radio__container {
	opacity: 1;
}
