.author {

	&--cover {
		width: 100%;
		height: 172px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 4px;
		margin-bottom: 20px;
	}

	&--about {
		margin-top: -5px;
		padding-bottom: 15px;
		display: flex;
		align-items: center;

		.drop-button {
			margin-left: auto;
		}

		.Button {
			margin-left: auto;
		}

		&.marathoned {
			margin-top: 0;
			padding-top: 15px;
		}
	}

	&--avatar {
		width: 50px;
		min-width: 50px;
		height: 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border-radius: 50%;
		cursor: pointer;
	}

	&--info {
		margin-left: 15px;
		display: flex;
		flex-direction: column;
	}

	&--name {
		margin-bottom: 3px;
		font-size: 13px;
		line-height: normal;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #2a5885;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover {
			text-decoration: underline;
		}
	}

	&--stats {
		font-size: 12.5px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		color: #939393;
	}
}
