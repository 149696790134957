.tabs-header {
	padding: 0 6px;
	box-sizing: border-box;
	width: 100%;
	font-family: SFPro;
	background-color: #fafbfc;
	border: 1px solid #e7e8ec;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	box-shadow: inset 0 -1px 0 0 #e7e8ec;

	.TabsItem {
		padding-right: 8px;
		padding-left: 8px;
		flex-grow: 0;
		font-size: 14px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.15px;
		color: #656565;
		cursor: pointer;

		&:after {
			bottom: 0;
			left: 8px;
			width: calc(100% - 16px);
			border-radius: 0;
		}

		&__in {
			padding: 19px 6px;
			display: flex;
			align-items: center;
		}

		&--selected {
			color: #000000;

			&:after {
				background: #5181b8;
			}
		}
	}
}

.tabs-inner {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	font-family: SFPro;

	> div:first-child.white-block {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.tabs-header {
		position: relative;
		padding-right: 20px;
		padding-left: 20px;
		width: auto;
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		box-shadow: none;

		&:before {
			content: '';
			position: absolute;
			right: 20px;
			bottom: 0;
			left: 20px;
			border: 1px solid #e3e4e8;
			border-top: none;
		}

		.TabsItem {
			padding-right: 4px;
			padding-left: 4px;

			&:after {
				bottom: 0;
				left: 4px;
				width: calc(100% - 8px);
				border-radius: 0;
			}

			&__in {
				padding: 15px 6px;
			}
		}
	}

	.empty-marathons {
		height: 454px;
		background: #ffffff;
		border: 1px solid #e7e8ec;
		border-top: none;
		border-radius:  0 0 4px 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		line-height: 1.38;
		letter-spacing: -0.08px;
		flex-direction: column;

		button {
			margin-top: 24px;
		}
	}
}
