.modal {

	&--open {
		overflow: hidden;
	}

	&--overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, .7);
	}

	&--content {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 99;
		max-height: 100%;
		font-family: SFProD;
		color: #ffffff;
		transform: translate(-50%, -50%);

		.Button {
			cursor: pointer;
		}

		> div {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	&--header {
		position: relative;
		padding: 0 25px;
		box-sizing: border-box;
		width: 100%;
		height: 54px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.15px;
		color: #ffffff;
		background: #5b88bd;
		border-radius: 4px 4px 0 0;
	}

	&--close {
		position: absolute;
		top: 15px;
		right: 20px;
		opacity: .5;
		cursor: pointer;
	}

	&--block {
		width: 450px;
		overflow: hidden;
		font-family: SFPro;
		color: #000000;
		background: #ffffff;
		border-radius: 4px;
	}
}

.modal--done {
	border-radius: 8px;

	.modal {

		&--body {
			padding: 34px 20px;
		}

		&--title {
			margin-bottom: 23px;
			font-size: 22px;
			line-height: normal;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			text-align: center;
			letter-spacing: .35px;
			color: #222222;
		}

		&--text {
			font-size: 16px;
			line-height: 1.44;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			text-align: center;
			letter-spacing: -.32px;
			color: #000000;

			p {
				margin: 0;

				&:not(:last-child) {
					margin-bottom: 11px;
				}
			}
		}

		&--rating {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;

			span {
				margin-bottom: 19px;
				font-size: 16px;
				line-height: 1.44;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				text-align: center;
				letter-spacing: -.32px;
				color: #000000;
			}
		}

		&--footer {
			margin-top: 48px;
			margin-bottom: 38px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&-two {
				margin-bottom: 23px;

				.Button + .Button {
					margin-top: 13px;
				}
			}
		}
	}

	.rating-stars {

		.icon path {
			stroke: #99a2ad;
			fill: #ffffff;
		}

		.icon--active path {
			stroke: #5181b8;
			fill: #5181b8;
		}
	}

	.Button__content {
		font-size: 14px;
	}
}
