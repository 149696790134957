.marathon {

	&--cover {
		position: relative;
		margin-bottom: 20px;
		padding: 20px;
		box-sizing: border-box;
		width: 100%;
		height: 172px;
		display: flex;
		overflow: hidden;
		align-items: flex-end;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 4px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, .4);
		}
	}

	&--about {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&--name {
		margin-bottom: 6px;
		font-family: SFProD;
		font-size: 32px;
		line-height: 1.13;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: .38px;
		color: #ffffff;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&--stats {
		display: flex;
		justify-content: space-between;
	}

	&--rating {
		display: flex;
		align-items: center;

		span {
			margin-right: 14px;
			display: block;
			font-size: 16px;
			line-height: 1.31;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.18px;
			color: #ffffff;
		}
	}

	&--action {
		margin-top: -10px;

		.Button--lvl-tertiary {
			color: #ffffff;
		}
	}

	&--info {
		margin-bottom: 20px;
		display: flex;
	}

	&--video {
		margin-right: 20px;
		width: 100%;
		max-width: 367px;
		height: 213px;
		overflow: hidden;
		background-color: #eeeeee;
		border-radius: 4px;
	}

	&--description {
		max-width: 368px;
		font-size: 13px;
		line-height: 1.31;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #222222;
	}

	&--done {
		margin-top: 4px;
		display: flex;
		align-items: center;
		font-size: 13px;
		line-height: 1.46;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: -.08px;
		color: #828282;

		.Icon {
			margin-top: 1px;
			margin-left: 5px;
		}
	}

	.author--about {
		margin-right: -20px;
		margin-left: -20px;
		padding-right: 20px;
		padding-left: 20px;
		border-top: 1px solid #e7e8ec;
	}

	&-program {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;

		&--item {
			padding-top: 10px;
			display: flex;
			flex-direction: column;
			cursor: pointer;

			&.inactive {
				opacity: 0.6;
				cursor: default;
			}

			&:not(:last-child){
				padding-bottom: 10px;
				border-bottom: 1px solid #e7e8ec;
			}
		}

		&--name {
			margin-top: 4px;
			margin-bottom: 4px;
			font-size: 13px;
			line-height: 1.23;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #222222;
		}

		&--action {
			display: flex;
			align-items: center;
			font-size: 13px;
			line-height: 1.46;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -.08px;
			color: #828282;

			.Icon {
				margin-top: 1px;
				margin-left: 5px;
			}

			span {
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}
}
