.breadcrumbs {
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;

	&--item {
		position: relative;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.32px;
		color: #656565;
		cursor: pointer;
		white-space: nowrap;

		& + & {
			padding-left: 10px;
			margin-left: 5px;

			&:before{
				content: '›';
				position: absolute;
				top: 3px;
				left: 0;
				color: #656565;
				font-size: 16px;
				line-height: 1;
			}
		}

		&:last-child {
			color: #000;
		}

		&:last-child {
			pointer-events: none;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&--active{
		color: #000000;
	}
}
