.test-layer {
	min-height: 530px;
	display: flex;
	flex-direction: column;

	&--content {
		margin-top: auto;
		margin-bottom: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&--footer {
		margin-right: -20px;
		margin-left: -20px;
		padding: 15px 0;
		display: flex;
		justify-content: center;
		border-top: 1px solid #e7e8ec;
	}

	.form-label {
		width: 500px;
	}
}

.test-question {
	margin-bottom: 28px;
	width: 371px;
	font-size: 16px;
	line-height: 1.44;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	text-align: center;
	letter-spacing: -.32px;
	color: #000000;
}

.test-answer {
	display: flex;
	flex-direction: column;
}

.test-pairs {
	width: 500px;
	display: flex;
	justify-content: space-between;
}

.pairs {

	&--column {
		width: calc(100%/2 - 10px);
	}

	&--content-box {

	}

	&--box {
		display: flex;
		align-items: flex-start;
		align-self: flex-start;
		box-sizing: border-box;
		max-width: 240px;
		width: 100%;
		border-radius: 3px;
		padding: 7px 12px 9px;
		border: 1px solid #d3d9de;
		background-color: #ffffff;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #000000;
		margin-bottom: 15px;
		transition: margin-bottom 0.1s ease-in-out;

		.icon-reorder {
			margin-top: 3px;
			margin-right: 10px;
		}
	}

	&--drag {
		cursor: pointer;
		position: relative;

		&:hover:not(:active) .tooltip {
			display: block;
		}

		.tooltip {
			bottom: 95%;
			left: 50%;
			transform: translateX(-50%);
		}

		&:active {
			cursor: grabbing;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
		}
	}
}
