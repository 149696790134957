.drop-button {
	position: relative;

	&.opened {

		.drop-button--menu {
			display: flex;
		}
	}

	&--selected {
		padding: 7px 16px 8px;
		display: flex;
		align-items: center;
		font-size: 12.5px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		text-align: right;
		letter-spacing: normal;
		color: #55677d;
		background: #e5ebf1;
		border-radius: 4px;
		cursor: pointer;

		.Icon {
			margin-top: 1px;
			margin-left: 5px;
		}
	}

	&--menu {
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		right: 0;
		z-index: 9;
		padding: 5px 0;
		flex-direction: column;
		text-align: left;
		background-color: #ffffff;
		border: solid 1px #c5d0db;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
		display: none;

		&:before {
			content: '';
			position: absolute;
			top: -5px;
			left: 0;
			right: 0;
			height: 4px;
		}
	}

	&--item {
		padding: 7px 16px 8px;
		max-height: 30px;
		font-size: 13px;
		line-height: normal;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		white-space: nowrap;
		letter-spacing: -.08px;
		color: #2a5485;
		cursor: pointer;

		&:hover {
			background: #e4eaf0;
		}
	}
}
